import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Gravatar from 'react-gravatar'
import Media from './Media'
import { FancyLinks } from './RootStyle'

export const query = graphql`
  query IntroQuery {
    site {
      siteMetadata {
        social {
          gravatar
        }
      }
    }
    intro: markdownRemark(frontmatter: { type: { eq: "intro" } }) {
      fields {
        slug
      }
      html
    }
  }
`

const IntroContainer = styled.header`
  display: flex;
  padding: 1rem 0 0.7rem 2rem;
  align-items: center;

  ${Media.phone`
    padding: 1rem 0 0.7rem 0;
    flex-flow: column; 
  `}

  .gravatar-image {
    border-radius: 50%;
  }
  ${FancyLinks}
`

const GravatarContainer = styled.div`
  align-self: center;
  text-align: right;
  padding-right: 1.5rem;

  ${Media.phone`
    width: auto;
    margin-bottom: 1rem;
    padding-right: 0;
  `}
`

const TextContainer = styled.div``

const Intro = ({
  data: {
    site: {
      siteMetadata: {
        social: { gravatar },
      },
    },
    intro: { html },
  },
}) => (
  <IntroContainer>
    <GravatarContainer>
      <Gravatar className="gravatar-image" email={gravatar} size={80} />
    </GravatarContainer>
    <TextContainer dangerouslySetInnerHTML={{ __html: html }} />
  </IntroContainer>
)

const IntroWithQuery = () => (
  <StaticQuery query={query} render={data => <Intro data={data} />} />
)

export default IntroWithQuery
