import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import PostMetadata from '../PostMetadata'
import Img from 'gatsby-image'
import Media from '../Media'
import MediaQuery from 'react-responsive'

const PostHeading = styled.h2`
  a {
    color: ${({ theme }) => theme.textColor};
    font-family: ${({ theme }) => theme.headerFont};
    font-family: ${({ theme }) => theme.headerFont};
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.textColor};
      text-decoration: underline;
    }
  }

  ${Media.tablet`
    font-size: 135%;
  `}
`

const PostCard = styled(Link)`
  transition: ease-in 0.2s all;
  padding: 1.5rem;
  border-bottom: 1px solid #dbdbdb;
  display: block;

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 2px 6px 2px rgba(60, 64, 67, 0.15);

    background: rgb(107, 161, 255, 0.09);

    ${PostHeading} {
      text-decoration: underline;
    }
  }

  ${Media.tablet`
    padding: 1rem 0;
  `}
`

const Excerpt = styled.p`
  color: ${({ theme }) => theme.excerptText};
  margin-top: 1rem;
  line-height: 150%;

  ${Media.phone`
    margin-top: 1.5rem; 
  `}
`
const PostNonFirstImage = styled.div`
  margin-right: 1.5rem;
  ${Media.phone`
    margin-right: 0.8rem;
  `}
`

const PostFirstImage = styled.div`
  margin-bottom: 1rem;
  margin-right: 0;
  width: 100%;
  height: auto;
  margin-right: 0;
`

const PromotedPostContainer = styled.article`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 220%;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 2rem;
  }
  ${Media.phone`
    h2 {
      font-size: 170%; 
      padding-bottom: 0.5rem;
    } 
  `}
`

const NormalPostContainer = styled.article`
  display: flex;
  flex-direction: row;
  h2 {
    font-size: 150%;
    padding-bottom: 0.5rem;
    font-weight: ${({ theme }) => theme.header.fontWeight};
  }
  ${Media.phone`
    h2 {
      font-size: 120%;
    }
  `}
`

const PromotedPost = ({ children }) => (
  <PromotedPostContainer className="promoted">{children}</PromotedPostContainer>
)

const NormalPost = ({ children }) => (
  <NormalPostContainer>{children}</NormalPostContainer>
)

const PostImage = ({ promoted, image: { fixed, fluid }, alt }) =>
  promoted ? (
    <PostFirstImage>
      <Img {...{ fluid, ...(alt && { alt }) }} />
    </PostFirstImage>
  ) : (
    <PostNonFirstImage>
      <Img {...{ fixed, ...(alt && { alt }) }} />
    </PostNonFirstImage>
  )

const Post = ({ promoted, image, children }) =>
  promoted ? (
    <PromotedPost {...{ image }}>{children}</PromotedPost>
  ) : (
    <NormalPost {...{ image }}>{children}</NormalPost>
  )

const PostCardContent = styled.div`
  display: flex;
  flex-flow: column;
`
const PostItem = styled.li`
  list-style-type: none;
  :first-of-type {
    border-top: 1px solid #dbdbdb;
  }
`

const PostListing = ({
  first: promoted,
  node: {
    excerpt,
    frontmatter: {
      title,
      date,
      tags,
      banner: {
        src: { childImageSharp: image = {} } = {},
        unsplash: { alt },
      },
    },
    fields: { slug },
  },
}) => (
  <PostItem>
    <PostCard to={slug}>
      <Post {...{ promoted }}>
        <PostImage {...{ promoted, image, alt }} />
        <PostCardContent>
          <PostHeading>{title}</PostHeading>
          <PostMetadata {...{ date, tags }} />
          <MediaQuery minWidth={576}>
            {matches => (matches || promoted) && <Excerpt>{excerpt}</Excerpt>}
          </MediaQuery>
        </PostCardContent>
      </Post>
    </PostCard>
  </PostItem>
)

export default PostListing
