import React from 'react'
import PostListings from '../components/blog/Listings'
import styled from 'styled-components'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Footer from '../components/Footer'

const Container = styled.div`
  max-width: ${({ theme }) => theme.maxwidth};
  margin: 0 auto;
  padding: 0 1rem;
`

const IndexPage = ({
  data: {
    site: {
      pathPrefix,
      siteMetadata: {
        description,
        url,
        siteName,
        title,
        social: { twitter, gravatar },
      },
    },
    blogPosts,
  },
}) => (
  <Layout isHomePage>
    <Container>
      <SEO
        title={title}
        url={`${url}${pathPrefix}`}
        siteName={siteName}
        description={description}
        social={{
          twitter,
          gravatar,
        }}
      />
      <Intro />
      <PostListings {...{ blogPosts }} />
      <Footer />
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        url
        description
        siteName
        title
        social {
          twitter
          gravatar
        }
      }
      pathPrefix
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { draft: { eq: false } }
        frontmatter: { type: { eq: "blog" } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            banner {
              src {
                childImageSharp {
                  fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(maxWidth: 1024, maxHeight: 550) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              unsplash {
                alt
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
