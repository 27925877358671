import React from 'react'
import PostListing from './Listing'
import styled from 'styled-components'

const PostsContainer = styled.ol`
  padding: 0;
  margin: 0;
`

const PostListings = ({ blogPosts: { edges } }) => (
  <PostsContainer>
    {edges.map(({ node: { id, ...node } }, index) => (
      <PostListing first={index === 0} key={id} node={node} />
    ))}
  </PostsContainer>
)

export default PostListings
